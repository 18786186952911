import React, {
  PropsWithChildren,
  createContext,
  useMemo,
  useState,
} from 'react'

type AnimationState = {
  isAnimating: boolean
  updateIsAnimating: (newValue: boolean) => void
}
type AnimationContextType = AnimationState

const AnimationContext = createContext<AnimationContextType>({
  isAnimating: false,
  updateIsAnimating: () => {},
})

export const AnimationsProvider: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const [isAnimating, setIsAnimating] = useState(false)

  const updateIsAnimating = (newValue: boolean) => {
    setIsAnimating(newValue)
  }

  const contextValue = useMemo(
    () => ({ isAnimating, updateIsAnimating }),
    [isAnimating]
  )

  return (
    <AnimationContext.Provider value={contextValue}>
      {children}
    </AnimationContext.Provider>
  )
}

export default AnimationContext
