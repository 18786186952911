module.exports = [{
      plugin: require('../node_modules/gatsby-theme-i18n/gatsby-browser.js'),
      options: {"plugins":[],"defaultLang":"pl","locales":"pl","configPath":"/opt/build/repo/src/translation/config.json"},
    },{
      plugin: require('../node_modules/gatsby-theme-i18n-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"locales":"./src/translation/locales","i18nextOptions":{"supportedLngs":["pl"],"ns":["hello"]}},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-source-wordpress/gatsby-browser.js'),
      options: {"plugins":[],"url":"https://indigitalcms.indigital.guru/graphql","html":{"useGatsbyImage":true,"generateWebpImages":true,"imageMaxWidth":null,"fallbackImageMaxWidth":1024,"imageQuality":70,"createStaticFiles":true,"generateAvifImages":false,"placeholderType":"dominantColor"},"schema":{"perPage":9,"requestConcurrency":3,"previewRequestConcurrency":2,"queryDepth":15,"circularQueryLimit":5,"typePrefix":"Wp","timeout":30000},"type":{"MediaItem":{"excludeFieldNames":["dateGmt","date","parent","ancestors","comments","author","authorDatabaseId","authorId","commentCount","commentStatus","enclosure","desiredSlug","modified","modifiedGmt","parentId","parentDatabaseId"],"localFile":{"requestConcurrency":5,"excludeByMimeTypes":[],"maxFileSizeBytes":15728640},"placeholderSizeName":"gatsby-image-placeholder","createFileNodes":true,"lazyNodes":false},"RootQuery":"{ excludeFieldNames: ['viewer', 'node', 'schemaMd5'], },"},"develop":{"nodeUpdateInterval":10000,"hardCacheMediaFiles":false,"hardCacheData":false},"verbose":true,"catchLinks":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-nprogress/gatsby-browser.js'),
      options: {"plugins":[],"color":"#663399","minimum":0.01,"showSpinner":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"House of Digital","short_name":"InDigital","start_url":"/","background_color":"#ffffff","theme_color":"#000000","display":"minimal-ui","icon":"src/assets/icons/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"d545004f922d55e0aca1e2226c34926a"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager-timeout/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-NP7885M6","includeInDevelopment":false,"timeout":2000,"defaultDataLayer":{"type":"object","value":{"platform":"gatsby"}}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
