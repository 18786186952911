import Lenis from 'lenis'

let lenisInstance: Lenis | null = null

if (typeof window !== 'undefined') {
  lenisInstance = new Lenis()
}

const lenis = lenisInstance

export { lenis }
