/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */
const { lenis } = require('./src/lib/lenis')
const { wrapRootElement, wrapPageElement } = require('./gatsby-shared')

const shouldUpdateScroll = ({
  getSavedScrollPosition,
  routerProps: { location },
}) => {
  lenis.stop()
  lenis.start()

  const position = getSavedScrollPosition(location)

  if (position) {
    window.scrollTo(position[0], position[1])
    // lenis.scrollTo(position[1])
  } else {
    window.scrollTo(0, 0)
    // lenis.scrollTo('top')
  }

  return false
}

exports.shouldUpdateScroll = shouldUpdateScroll
exports.wrapRootElement = wrapRootElement
exports.wrapPageElement = wrapPageElement
