exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-case-study-tsx": () => import("./../../../src/pages/case-study.tsx" /* webpackChunkName: "component---src-pages-case-study-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-informacje-prasowe-tsx": () => import("./../../../src/pages/informacje-prasowe.tsx" /* webpackChunkName: "component---src-pages-informacje-prasowe-tsx" */),
  "component---src-pages-kariera-tsx": () => import("./../../../src/pages/kariera.tsx" /* webpackChunkName: "component---src-pages-kariera-tsx" */),
  "component---src-pages-klienci-tsx": () => import("./../../../src/pages/klienci.tsx" /* webpackChunkName: "component---src-pages-klienci-tsx" */),
  "component---src-pages-kontakt-tsx": () => import("./../../../src/pages/kontakt.tsx" /* webpackChunkName: "component---src-pages-kontakt-tsx" */),
  "component---src-pages-o-nas-tsx": () => import("./../../../src/pages/o-nas.tsx" /* webpackChunkName: "component---src-pages-o-nas-tsx" */),
  "component---src-pages-oferta-tsx": () => import("./../../../src/pages/oferta.tsx" /* webpackChunkName: "component---src-pages-oferta-tsx" */),
  "component---src-pages-polityka-prywatnosci-tsx": () => import("./../../../src/pages/polityka-prywatnosci.tsx" /* webpackChunkName: "component---src-pages-polityka-prywatnosci-tsx" */),
  "component---src-templates-informacje-prasowe-template-tsx": () => import("./../../../src/templates/informacje-prasowe-template.tsx" /* webpackChunkName: "component---src-templates-informacje-prasowe-template-tsx" */),
  "component---src-templates-oferta-pracy-template-tsx": () => import("./../../../src/templates/oferta-pracy-template.tsx" /* webpackChunkName: "component---src-templates-oferta-pracy-template-tsx" */),
  "component---src-templates-projekt-template-tsx": () => import("./../../../src/templates/projekt-template.tsx" /* webpackChunkName: "component---src-templates-projekt-template-tsx" */),
  "component---src-templates-usluga-template-tsx": () => import("./../../../src/templates/usluga-template.tsx" /* webpackChunkName: "component---src-templates-usluga-template-tsx" */)
}

