import type { Breakpoints, Colors, Fonts } from 'types/theme'

export const breakpoints: Breakpoints = {
  base: 0,
  xs: 400,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1440,
  xxxl: 1600,
}

export const colors: Colors = {
  gray100: '#000000',
  gray80: '#262626',
  gray60: '#7B7B7B',
  gray40: '#C4C4C4',
  gray10: '#D9D9D9',
  gray05: '#E9E9E9',
  gray0: '#F5F5F5',

  white100: '#FFFFFF',
  white72: 'rgba(255, 255, 255, 0.72)',
  white40: 'rgba(255, 255, 255, 0.40)',
  white24: 'rgba(255, 255, 255, 0.24)',
  white12: 'rgba(255, 255, 255, 0.12)',

  primary100: '#5624F4',
  primary50: '#6429FB',
  primary20: '#824FFD',
  primary10: '#9C72FE',
  primary05: '#D6C4FD',
  primary0: '#F0E7FE',

  success100: '#116942',
  success50: '#1BA769',
  success05: '#DEF2E9',

  warning100: '#A64F21',
  warning50: '#E86825',
  warning05: '#FFE1BE',

  alert100: '#9F1B1F',
  alert50: '#DE1C22',
  alert05: 'FFE0E1',

  dark: '#242424',
  text: '#454545',

  white: '#FFFFFF',
  black: '#000000',
}

export const fonts: Fonts = {
  size: '16px',
}

const theme = {
  breakpoints,
  colors,
  fonts,
}

export default theme
