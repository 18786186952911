const React = require('react')
const { ThemeProvider } = require('styled-components')

const { default: theme } = require('styles/theme')
const { default: GlobalStyles } = require('styles/global')
// const { BreakpointsProvider } = require('contexts/BreakpointsContext')
const { AnimationsProvider } = require('contexts/AnimationContext')

require('@fontsource-variable/plus-jakarta-sans')

exports.wrapRootElement = ({ element }) => (
  <ThemeProvider theme={theme}>
    {/* <BreakpointsProvider> */}
    <AnimationsProvider>{element}</AnimationsProvider>
    {/* </BreakpointsProvider> */}
  </ThemeProvider>
)

exports.wrapPageElement = ({ element }) => (
  <>
    <GlobalStyles />
    {element}
  </>
)
